import React from "react";
import "../../../styles/inlineDesktop.css";
import InlineTopBar from "../InlineTopBar";
import useXrayInline from "../../../controllers/useXrayInline";
import Questions from "./Questions";
import CurrentXrayWorkflow from "../../../workflow/CurrentXrayWorkflow";
import CtaWorkflow from "../../../../../ctas/workflow/CtaWorkflow";
import { useSelector } from "react-redux";
import GoogleAdsWorkflow from "../../../../../common/googleAds/workflow/GoogleAdsWorkflow";
import Refinery89Ads from "../../../../../common/googleAds/components/Refinery89Ads";
import Feedback from "../../../../reusable/components/feedbacks/Feedback";
import PoweredBy from "../../../../reusable/components/poweredBy/PoweredBy";

function InlineDesktop() {
  const disclaimer = useSelector(
    (state) => state.flipcard.data.config.disclaimer
  );

  const showGoogleAd = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig?.isActive
  );

  const showRefineryAdConfig = useSelector(
    (state) => state.flipcard.data?.config?.refineryAdConfig?.isActive
  );

  const {
    allOptions,
    selectedOption,
    ctaIndex,
    isShowCta,
    onSelectedOptionHandler,
    handleCtaBackButton,
  } = useXrayInline();

  return (
    <div className="xray">
      <InlineTopBar
        isShowCta={isShowCta()}
        handleCtaBackButton={handleCtaBackButton}
        isDesktop={true}
        selectedOption={selectedOption}
        allOptions={allOptions}
      />

      <div
        className={`xrayInlineDesktop xrayInlineDesktopBody customScrollBar ${
          isShowCta() && "xrayBgBlur"
        }`}
      >
        <Questions
          allOptions={allOptions}
          selectedOption={selectedOption}
          onSelectedOptionHandler={onSelectedOptionHandler}
        />
        <div className="xrayInlineDesktopAnswers">
          <CurrentXrayWorkflow currentEngData={selectedOption} />
        </div>
      </div>

      {showGoogleAd && (
        <div
          style={{
            padding: "0px var(--mpr-1-2)",
          }}
          className={`${isShowCta() && "xrayBgBlur"} `}
        >
          <div className="googleAdsParent">
            <GoogleAdsWorkflow />
          </div>
        </div>
      )}

      {showRefineryAdConfig && <Refinery89Ads isMobile={false} />}

      {isShowCta() && (
        <div className="gatedCtaOverlay xrayOverlayInlineDesktop">
          <CtaWorkflow actionIndex={ctaIndex} isTransparent={true} />
        </div>
      )}

      <div
        style={{
          padding: "0px var(--mpr-1-2)",
        }}
      >
        <Feedback isShowCta={isShowCta()} />
      </div>
      <PoweredBy isShowCta={isShowCta()} />
    </div>
  );
}

export default InlineDesktop;
