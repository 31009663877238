import React, { useEffect, useState } from "react";

function Icon({ name, size = "medium", style = {}, color, onClick, type }) {
  const initialSizeStyle = {
    fontSize: size === "small" ? "8px" : "25px",
  };

  const colorClassName = color === "white" && "whiteIcon";

  const [sizeStyle, setSizeStyle] = useState(initialSizeStyle);

  useEffect(() => {
    setSizeStyle({
      fontSize: size === "small" ? "8px" : "25px",
    });
  }, [size]);

  if (type === "secondary") {
    return (
      <img
        className={colorClassName}
        onClick={onClick}
        style={{ ...sizeStyle, ...style }}
        src={`../../../icons/${name}.svg`}
        alt={name}
      />
    );
  }

  return (
    <i
      onClick={onClick}
      className={`${name} icon`}
      style={{ ...sizeStyle, ...style, color: color }}
    ></i>
  );
}

export default Icon;
