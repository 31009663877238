import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import getTextColor from "../../../../../utils/controllers/getTextColor";
import useFeedbackHandler from "../controllers/useFeedbackHandler";
import "../styles/feedback.css";

function FeedbackTwo({ isShowCta }) {
  const primaryColor = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor
  );
  const { rating, changeRating } = useFeedbackHandler();
  const feedback = useSelector(
    (state) => state.flipcard.data.config?.feedbackConfig
  );

  if (feedback?.isActive) {
    return (
      <div
        className="FeedbackContainerParent"
        style={{
          height: "45px",
        }}
      >
        <div
          className={`FeedbackContainer ${isShowCta && "xrayBgBlur"} `}
          style={{
            borderRadius: "0px",
            backgroundColor: "var(--primary-Color)",
            padding: "var(--mpr-mini)",
          }}
        >
          {feedback?.isActive && (
            <div
              className="RatingsDiv"
              style={{
                justifyContent: "center",
              }}
            >
              <div>
                <StarRatings
                  rating={rating}
                  starRatedColor="#ffa106"
                  changeRating={changeRating}
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  starSpacing="1px"
                  starHoverColor="#ffa106"
                  starEmptyColor="white"
                />
              </div>
              <p
                style={{
                  marginLeft: "var(--mpr-3)",
                  color: getTextColor(primaryColor),
                  fontWeight: 500,
                }}
              >
                {feedback?.title || "Rate your experience"}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
  return <div />;
}

export default FeedbackTwo;
