import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "../../loader/workflow/Loader";
import { useSelector } from "react-redux";

function Login({
  onSubmitHandler,
  isSmall,
  style,
  loading,
  isReturnAccessToken,
}) {
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);
  const loginWithGoogle_TEXT = useSelector(
    (state) => state.language.data.loginWithGoogle_TEXT
  );

  const [isLoading, setIsLoading] = useState(false);
  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (response) => {
      setIsLoading(true);
      if (response.access_token) {
        if (isReturnAccessToken) {
          onSubmitHandler({
            accessToken: response.access_token,
          });
          return;
        }
        if (!isReturnAccessToken) {
          const userInfoResponse = await fetch(
            "https://www.googleapis.com/oauth2/v1/userinfo",
            {
              headers: {
                Authorization: `Bearer ${response.access_token}`,
              },
            }
          );

          if (userInfoResponse.ok) {
            const userInfo = await userInfoResponse.json();
            const email = userInfo.email;
            const fullname = userInfo.name;

            onSubmitHandler({
              email,
              fullname,
            });
          }
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <button
      style={{
        ...style,
      }}
      className="googleLogin"
      onClick={() => {
        if (mode === "create") {
          return;
        }
        googleLoginHandler();
      }}
    >
      {isLoading || loading ? (
        <Loader style={{ borderBottomColor: "black" }} />
      ) : (
        <>
          <img
            src="/icons/googleOutlined.svg"
            style={{
              width: "17px",
            }}
            alt="Google"
          />
          {!isSmall && (
            <p
              style={{
                fontSize: "0.8rem",
                marginLeft: "var(--mpr-2)",
                fontWeight: 500,
              }}
            >
              {loginWithGoogle_TEXT}
            </p>
          )}
        </>
      )}
    </button>
  );
}

export default Login;
