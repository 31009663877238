import { useDispatch, useSelector } from "react-redux";
import { useCtaTakeMutation } from "../../../../services/api";
import {
  flipTheCard,
  setEngagementTimer,
  setIsCtaTaken,
  setIsShowEndScreen,
  setLinkedActions,
} from "../../../../redux/slices/appSlice";
import useMessage from "../../../../utils/presentation/message/controllers/useMessage";

function useRegistrationController() {
  const thankYouForRegistration_TEXT = useSelector(
    (state) => state.language.data.thankYouForRegistration_TEXT
  );
  const isEndScreen = useSelector(
    (state) => state.flipcard?.data?.flipCard?.endScreen?._id
  );

  const ctas = useSelector((state) => state.flipcard.data.flipCard?.actions);

  const linkedActions = useSelector(
    (state) => state.flipcard.data.flipCard?.linkedActions
  );

  const isGatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const isRecirculation = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType === "recirculation"
  );

  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const { showMessage } = useMessage();
  const dispatch = useDispatch();
  const [_CTA_TAKE, { isLoading }] = useCtaTakeMutation();

  const handleEndScreen = () => {
    if (isEndScreen) {
      dispatch(setIsShowEndScreen(true));
    }
  };

  const handleSubmitRegistration = async (sentData) => {
    if (mode === "create") {
      return;
    }
    const response = await _CTA_TAKE(sentData);

    if (response?.data?.success) {
      showMessage("success", thankYouForRegistration_TEXT);
      dispatch(setIsCtaTaken(true));
      if (isRecirculation && isGatedEngagement) {
        handleTimer();
        handleRecirculationActions();
      } else {
        handleEndScreen();
      }
    }

    if (response?.error) {
      showMessage("error", response?.error?.data?.errorObject?.userErrorText);
    }
  };

  const handleTimer = () => {
    let counter = 0;
    const intervalId = setInterval(() => {
      dispatch(setEngagementTimer(counter));
      counter += 1;
      if (counter > 100) {
        clearInterval(intervalId);
        if (ctas?.length > 0) {
          dispatch(flipTheCard());
        }
      }
    }, 30);
  };

  const handleRecirculationActions = () => {
    if (linkedActions?.length > 0) {
      return;
    }
    const allLinkActions = ctas?.filter(
      (data) => data?.actionData?.actionType === "link"
    );
    dispatch(setLinkedActions(allLinkActions || []));
  };

  return { handleSubmitRegistration, isLoading };
}

export default useRegistrationController;
