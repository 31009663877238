import React from "react";
import "../styles/poll.css";
import TopBar from "../../reusable/components/TopBar";
import Body from "../components/Body";

function PollWorkflow() {
  return (
    <div className="poll">
      <TopBar />
      <Body />
    </div>
  );
}

export default PollWorkflow;
