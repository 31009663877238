import { useSelector } from "react-redux";
import useEngController from "../../reusable/controllers/useEngController";
import { useEffect, useState } from "react";

function useXrayInline() {
  const [isOptionClicked, setIsOptionClicked] = useState(false);
  const { handleXraytake } = useEngController();
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const [ctaIndex, setCtaIndex] = useState(-1);
  const options = useSelector(
    (state) => state.flipcard.data.flipCard.engagement?.engagementData?.options
  );
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const [allOptions, setAllOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [preCtaOption, setPreCtaOption] = useState(false);

  useEffect(() => {
    if (options?.length > 0) {
      const summaryObj = options[0];
      setAllOptions(options);
      handleTakeApi(summaryObj);
    }
  }, [options]);

  useEffect(() => {
    const index = ctas.findIndex(
      (action) => action.actionData.actionType !== "link"
    );
    if (index >= 0) {
      setCtaIndex(index);
    }
  }, [ctas]);

  useEffect(() => {
    if (isCtaTaken) {
      handleTakeApi(preCtaOption);
    }
  }, [isCtaTaken]);

  const onSelectedOptionHandler = (data) => {
    setPreCtaOption(data);
    setIsOptionClicked(true);
    if (ctas?.length < 0 || !ctas?.length || ctaIndex === -1) {
      handleTakeApi(data);
    }
    if (isCtaTaken) {
      handleTakeApi(data);
    }
  };

  const isShowCta = () => {
    if (ctaIndex === -1) {
      return false;
    }
    return !isCtaTaken && ctas?.length > 0 && isOptionClicked;
  };

  const handleCtaBackButton = () => {
    setIsOptionClicked(false);
  };

  const handleTakeApi = (data) => {
    setSelectedOption(data);
    handleXraytake(data?._id);
  };

  return {
    allOptions,
    selectedOption,
    ctaIndex,
    onSelectedOptionHandler,
    isShowCta,
    handleCtaBackButton,
  };
}

export default useXrayInline;
