import "../styles/rag.css";
import TopBar from "../components/TopBar";
import Body from "../components/Body";
import useRagController from "../controllers/useRagController";

function RagWorkflow() {
  const {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    isShowToggleIcon,
  } = useRagController();

  return (
    <div className="rag">
      <TopBar
        isLoading={isLoading}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isRagCollapsed={isRagCollapsed}
        isShowToggleIcon={isShowToggleIcon}
      />
      <Body
        responseData={responseData}
        isRagCollapsed={isRagCollapsed}
        setInputQuery={setInputQuery}
        handleGenerateAnswer={handleGenerateAnswer}
      />
    </div>
  );
}

export default RagWorkflow;
