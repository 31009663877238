import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsRagCollapsed,
  setIsRagFirstQuestion,
} from "../../../../redux/slices/appSlice";
import usePostMessage from "../../../../utils/controllers/usePostMessage";
import {
  useEngagementTakeMutation,
  useSubmitRagQueryMutation,
} from "../../../../services/api";

function useRagController() {
  const dispatch = useDispatch();
  const [isShowToggleIcon, setIsShowToggleIcon] = useState(false);
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const engagementId = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?._id
  );

  const options = useSelector(
    (state) => state.flipcard.data.flipCard?.engagement?.engagementData?.options
  );

  const questions = useSelector(
    (state) =>
      state.flipcard.data.flipCard?.engagement?.engagementData?.questions
  );

  const [isLoading, setIsLoading] = useState(false);
  const [inputQuery, setInputQuery] = useState("");
  const [responseData, setResponseData] = useState([]);
  const { postMessage } = usePostMessage();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const [_ENGAGEMENT_TAKE] = useEngagementTakeMutation();
  const [_SEND_RAG_QUERY] = useSubmitRagQueryMutation();

  const handleGenerateAnswer = async (localQuestion) => {
    setIsLoading(true);
    takeAPi();

    try {
      const response = await _SEND_RAG_QUERY({
        question: inputQuery || localQuestion,
      });

      if (response?.data?.data?.data) {
        const sentData = {
          ...response?.data?.data?.data,
          question: inputQuery || localQuestion,
          ragResultId: response?.data?.data.ragResultId,
        };

        setResponseData([sentData, ...responseData]);
        setInputQuery("");
      }
      setIsLoading(false);
      setIsShowToggleIcon(true);
      dispatch(setIsRagCollapsed(false));
      if (isRagCollapsed) {
        sendCollapseEvent();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const takeAPi = async (_id) => {
    let optionId = "";

    if (options?.length > 0) {
      optionId = options[0]?._id;
    }
    const sentData = {
      engagementId: engagementId,
      optionId: optionId,
    };

    await _ENGAGEMENT_TAKE(sentData);
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  useEffect(() => {
    if (questions?.length > 0) {
      dispatch(setIsRagCollapsed(false));
      setIsShowToggleIcon(true);
      sendCollapseEvent();
    }
  }, [questions]);

  useEffect(() => {
    if (responseData?.length === 1) {
      dispatch(setIsRagFirstQuestion(true));
    }
  }, [responseData]);

  return {
    isLoading,
    inputQuery,
    responseData,
    isRagCollapsed,
    setInputQuery,
    handleGenerateAnswer,
    isShowToggleIcon,
  };
}

export default useRagController;
