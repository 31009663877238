import React from "react";
import { useSelector } from "react-redux";
import "../styles/poweredby.css";

function PoweredByTwo({ isShowCta }) {
  const poweredBy_TEXT = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );

  const disclaimer = useSelector(
    (state) => state.flipcard.data.config.disclaimer
  );

  const poweredByBridged = useSelector(
    (state) => state.flipcard.data.config?.poweredByBridged
  );

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const injectionStyle = useSelector(
    (state) => state.flipcard.data.config.injectionStyle
  );

  if (poweredByBridged || disclaimer) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: disclaimer ? "space-between" : "center",
          paddingLeft: "var(--mpr-1-2)",
          paddingRight: "var(--mpr-1-2)",
          paddingBottom: "var(--mpr-1-2)",
          paddingTop:
            injectionStyle !== "inline" ? "var(--mpr-1-2)" : "var(--mpr-3)",
        }}
        className="PowerdByTwoWrapper"
      >
        <p
          className={`PowerdBy ${isShowCta && "xrayBgBlur"} `}
          style={{ padding: "0px", opacity: 1 }}
        >
          <span style={{ opacity: 1 }}>{poweredBy_TEXT} </span>
          <img
            alt="bridged"
            style={{ cursor: "pointer", opacity: 1 }}
            onClick={() => {
              window.open("https://bridged.media?utm_source=card", "_blank");
            }}
            src={bridgedLogo}
          />
        </p>
        {disclaimer && (
          <p style={{ fontWeight: 500, textAlign: "center" }}>{disclaimer}</p>
        )}
      </div>
    );
  }

  return <div />;
}

export default PoweredByTwo;
