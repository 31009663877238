import React, { useState, useEffect } from "react";
import "../styles/progressCounter.css";
import { useSelector } from "react-redux";
import EngFlipIcon from "../../../components/EngFlipIcon";

function ProgressCounter() {
  const isGatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const isRecirculation = useSelector(
    (state) => state?.flipcard?.data?.config?.campaignType === "recirculation"
  );

  const isEndScreen = useSelector(
    (state) => state.flipcard?.data?.flipCard?.endScreen?._id
  );
  const ctas = useSelector((state) => state.flipcard.data.flipCard?.actions);
  const engagementTimer = useSelector((state) => state.app.engagementTimer);

  const [stateCounter, setStateCounter] = useState(0);

  useEffect(() => {
    const newStateCounter = Math.round((engagementTimer / 100) * 3);
    setStateCounter(newStateCounter);
  }, [engagementTimer]);

  const counter = () => {
    return (
      <>
        {(ctas?.length > 0 || isEndScreen) && (
          <>
            {engagementTimer === 100 ? (
              <EngFlipIcon />
            ) : (
              <div
                className="progressCounter"
                role="progressCounter"
                style={{
                  "--progressValue": engagementTimer,
                  visibility: stateCounter == 0 ? "hidden" : "visible",
                }}
              >
                <span>{stateCounter}</span>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  if (isGatedEngagement && isRecirculation) {
    return counter();
  }

  if (isGatedEngagement) {
    return <></>;
  }

  return <>{counter()}</>;
}

export default ProgressCounter;
