import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../../utils/components/Icon";
import { setIsXrayCollapsed } from "../../../../../redux/slices/appSlice";
import usePostMessage from "../../../../../utils/controllers/usePostMessage";
import getTextColor from "../../../../../utils/controllers/getTextColor";

function InlineTopBar({
  isShowCta,
  handleCtaBackButton,
  isDesktop,
  selectedOption,
  allOptions,
}) {
  const headingText = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.title
  );

  const inlineXrayHeaderSize = useSelector(
    (state) => state.app.inlineXrayHeaderSize
  );

  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);
  const poweredBy_TEXT = useSelector(
    (state) => state.language.data.poweredBy_TEXT
  );
  const backgroundColor = useSelector(
    (state) =>
      state.flipcard.data.flipCard.engagement.engagementData.backgroundColor
  );
  const { postMessage } = usePostMessage();
  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const backIcon = () => {
    return (
      <Icon
        name="ri-arrow-left-s-line"
        style={{
          marginLeft: "var(--mpr-mini)",
          cursor: "pointer",
          zIndex: 200,
        }}
        onClick={handleCtaBackButton}
      />
    );
  };

  const closeIcon = () => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-arrow-down-s-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            opacity: 0.8,
            cursor: "pointer",
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-close-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            opacity: 0.8,
            cursor: "pointer",
          }}
        />
      );
    }
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  useEffect(() => {
    const bridgedCardElement = document.querySelector(".bridgedCard");
    if (bridgedCardElement) {
      bridgedCardElement.style.height = isXrayCollapsed
        ? `${inlineXrayHeaderSize}px`
        : "100vh";
    }
  }, [isXrayCollapsed, inlineXrayHeaderSize]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: backgroundColor,
        paddingTop: isDesktop ? "25px" : "15px",
        color: backgroundColor
          ? getTextColor(backgroundColor)
          : "var(--text-Color)",
      }}
      className={`engTopbarLogoDiv xrayTopBarInline`}
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      {isShowCta && backIcon()}
      <div>
        {!logo && <p>{poweredBy_TEXT}</p>}
        <img
          className={`${isShowCta && "xrayBgBlur"}`}
          alt="Logo"
          onClick={() => {
            if (!logo) {
              window.open("https://bridged.media/", "_blank");
            }
          }}
          src={logo || bridgedLogo}
        />
        {!isDesktop && (
          <p
            style={{ fontSize: "0.95rem", opacity: 1, marginTop: "3px" }}
            className={`firstLine ${isShowCta && "xrayBgBlur"}`}
          >
            {headingText ||
              selectedOption?.questionText ||
              allOptions[0]?.questionText ||
              ""}
          </p>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          gap: 5,
        }}
        className={`${isShowCta && "xrayBgBlur"}`}
      >
        {isDesktop && (
          <p
            style={{ fontSize: "0.95rem", opacity: 1, marginTop: "3px" }}
            className="firstLine"
          >
            {headingText ||
              selectedOption?.questionText ||
              allOptions[0]?.questionText ||
              ""}
          </p>
        )}
        {closeIcon()}
      </div>
    </div>
  );
}

export default InlineTopBar;
