import React from "react";
import { useDispatch } from "react-redux";
import { flipTheCard } from "../../redux/slices/appSlice";
import Icon from "./Icon";

function FlipIcon({ color }) {
  const dispatch = useDispatch();

  const handleFlip = () => {
    dispatch(flipTheCard());
  };
  return (
    <div className="flipIcon" onClick={handleFlip}>
      <Icon name="flip" color={color} type="secondary" />
    </div>
  );
}

export default FlipIcon;
