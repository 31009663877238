import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function Refinery89Ads({ isMobile }) {
  const url = useSelector(
    (state) => state.flipcard.data?.config?.refineryAdConfig?.url
  );

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Create and append the internal script
      const internalScript = document.createElement("script");
      internalScript.innerHTML = `
        try {
          r89.pushAds();
        } catch (error) {
          console.error("Error in r89.pushAds():", error);
        }
      `;
      document.head.appendChild(internalScript);

      // Cleanup function to remove scripts
      return () => {
        document.head.removeChild(internalScript);
      };
    }
  }, [url]);

  if (isMobile) {
    return (
      <ins className="r89-ad" data-adunit="mobile-rectangle-infinite-fb"></ins>
    );
  }

  return <ins className="r89-ad" data-adunit="desktop-rectangle-btf"></ins>;
}

export default Refinery89Ads;
