import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleLeftBorder from "../../../../../utils/components/TitleLeftBorder";
import Divider from "../../../../../utils/components/Divider";
import Icon from "../../../../../utils/components/Icon";
import usePostMessage from "../../../../../utils/controllers/usePostMessage";
import useEngController from "../../../reusable/controllers/useEngController";
import { setIsXrayCollapsed } from "../../../../../redux/slices/appSlice";
import getTextColor from "../../../../../utils/controllers/getTextColor";

function XRayTopBar({
  currentEngData,
  totalEngagements,
  handlePopEngagementData,
  isShowCta,
}) {
  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const headingText = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.title
  );
  const backgroundColor = useSelector(
    (state) =>
      state.flipcard.data.flipCard.engagement.engagementData.backgroundColor
  );

  const primaryColor = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor
  );

  const { handleXraytake } = useEngController();

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const { postMessage } = usePostMessage();

  const backIcon = () => {
    return (
      <Icon
        name="ri-arrow-right-s-line"
        style={{
          cursor: "pointer",
        }}
        onClick={handlePopEngagementData}
      />
    );
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  const closeIcon = () => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-arrow-down-s-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            opacity: 0.8,
            cursor: "pointer",
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-arrow-up-s-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            opacity: 0.8,
            cursor: "pointer",
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (!isXrayCollapsed) {
      handleXraytake(currentEngData?._id);
    }

    // Accessing the bridgedCard element and applying dynamic height
    const bridgedCardElement = document.querySelector(".bridgedCard");
    if (bridgedCardElement) {
      bridgedCardElement.style.height = isXrayCollapsed ? "75px" : "100vh";
    }
  }, [isXrayCollapsed]);

  return (
    <div
      className="xrayDefaultTopbar"
      style={{
        cursor: isXrayCollapsed && "pointer",
      }}
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      <div
        className="xrayDefaultTopbarTitle"
        style={{
          backgroundColor: backgroundColor,
          color: backgroundColor
            ? getTextColor(backgroundColor)
            : "var(--text-Color)",
        }}
      >
        <div
          style={{
            marginRight: "var(--mpr-3)",
          }}
        >
          {closeIcon()}
        </div>
        <h3>
          <span className="firstLine">
            {isXrayCollapsed
              ? headingText || currentEngData?.questionText
              : currentEngData?.questionText}
          </span>
          {totalEngagements > 1 && !isXrayCollapsed && backIcon()}
        </h3>
      </div>
    </div>
  );
}

export default XRayTopBar;
