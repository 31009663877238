import React, { useState, useCallback } from "react";
import Divider from "../../../../../utils/components/Divider";
import Icon from "../../../../../utils/components/Icon";
import useGetWindowWidth from "../../../../../utils/controllers/useGetWindowWidth";
import useNewsletterController from "../../controllers/useNewsletterController";
import Button from "../../../../../utils/components/Button";
import Input from "../../../../../utils/components/Input";
import GoogleLogin from "../../../../../utils/presentation/googleLogin/workflow/GoogleLogin";
import { useSelector } from "react-redux";

function Body({ toggleDisplay, handleToggleDisplay, data: userData }) {
  const language = useSelector((state) => state.language.data);
  const {
    fullName_TEXT,
    enterFullname_TEXT,
    emailNotValid_TEXT,
    email_TEXT,
    newsletterSource_TEXT,
    back_TEXT,
    signUp_TEXT,
  } = language;

  const [data, setData] = useState({ fullname: "", email: "" });
  const [errors, setErrors] = useState({});

  const { handleSubmitNewsletter, isLoading } = useNewsletterController();
  const width = useGetWindowWidth();

  const { actionData } = userData;
  const { signUpButtonText } = actionData;

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    }

    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBackButtonClick = useCallback(() => {
    handleToggleDisplay(false);
  }, [handleToggleDisplay]);

  const handleSignUp = useCallback(() => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email.trim()) {
      newErrors.email = emailNotValid_TEXT;
    } else if (!emailRegex.test(data.email.trim())) {
      newErrors.email = emailNotValid_TEXT;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors)?.length === 0) {
      submitData(data);
    }
  }, [userData, data]);

  const submitData = (passedData) => {
    handleSubmitNewsletter({
      actionId: userData?._id,
      takeActionData: {
        actionType: "newsLetterSignup",
        ...passedData,
      },
    });
  };

  return (
    <div
      className="newsletterBody"
      style={{
        display: width > 500 || width === 0 || toggleDisplay ? "flex" : "none",
      }}
    >
      <div className="newsletterBodyTop">
        <div className="topArrow">
          <span onClick={handleBackButtonClick}>
            <Icon name="ri-arrow-left-s-line" />
            {back_TEXT}
          </span>
          <Divider style={{ margin: "var(--mpr-3) 0px" }} />
        </div>
        <form autoComplete="on">
          <h5>{email_TEXT}</h5>
          <Input
            type="email"
            name="email"
            onChange={handleInputChange}
            placeholder={newsletterSource_TEXT}
            isRequired={true}
            error={errors.email}
          />
          <h5>
            {fullName_TEXT} {"(optional)"}
          </h5>
          <Input
            name="fullname"
            onChange={handleInputChange}
            placeholder={enterFullname_TEXT}
          />
        </form>
      </div>
      <div className="newsletterBodyBottom">
        <GoogleLogin onSubmitHandler={submitData} />

        <Divider />
        <Button onClick={handleSignUp} isLoading={isLoading}>
          {signUpButtonText || signUp_TEXT}
        </Button>
      </div>
    </div>
  );
}

export default Body;
