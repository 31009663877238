import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../utils/components/Input";
import Button from "../../../../utils/components/Button";
import { setIsRagCollapsed } from "../../../../redux/slices/appSlice";
import Icon from "../../../../utils/components/Icon";
import usePostMessage from "../../../../utils/controllers/usePostMessage";
import TopBarFAQ from "./TopBarFAQ";

function TopBar({
  isLoading,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isRagCollapsed,
  isShowToggleIcon,
}) {
  const { ragLoaderTexts, generateText } = useSelector(
    (state) => state.language.data
  );

  const { postMessage } = usePostMessage();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [loaderTextIndex, setLoaderTextIndex] = useState(0);

  useEffect(() => {
    if (isLoading) {
      if (loaderTextIndex < ragLoaderTexts?.length - 1) {
        const interval = setInterval(() => {
          setLoaderTextIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex >= ragLoaderTexts?.length - 1) {
              clearInterval(interval);
            }
            return newIndex;
          });
        }, 2000);
        return () => clearInterval(interval);
      }
    } else {
      setLoaderTextIndex(0);
    }
  }, [isLoading, loaderTextIndex]);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const engagementTitle = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  const { poweredBy_TEXT, enterQuestion } = useSelector(
    (state) => state.language.data
  );

  const logo = useSelector(
    (state) => state.flipcard.data.flipCard.publisher.logo
  );

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const handleGenerate = useCallback(() => {
    const newErrors = {};

    if (!inputQuery.trim()) {
      newErrors.inputQuery = "Please enter a question.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors)?.length === 0) {
      handleGenerateAnswer();
    }
  }, [inputQuery, handleGenerateAnswer]);

  const closeIcon = (style) => {
    if (isRagCollapsed) {
      return (
        <Icon
          name="ri-arrow-up-s-line"
          onClick={() => {
            onCollapseHandler(false);
            sendCollapseEvent();
          }}
          style={{ ...style }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-arrow-down-s-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            ...style,
          }}
        />
      );
    }
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsRagCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: "toggleCollapsion",
      trackerId: getQueryParam("trackerId"),
    });
  };

  return (
    <div className="ragTopBar">
      {isShowToggleIcon &&
        closeIcon({
          opacity: 0.8,
          cursor: "pointer",
          position: "fixed",
          top: "var(--mpr-2)",
          right: "var(--mpr-2)",
        })}
      <div
        style={{
          display: "grid",
          gap: "var(--mpr-mini)",
          marginBottom: "var(--mpr-3)",
        }}
      >
        <div className="ragLogoDiv">
          {!logo && <p>{poweredBy_TEXT}</p>}
          <img
            onClick={() => {
              if (!logo) {
                window.open("https://bridged.media/", "_blank");
              }
            }}
            src={logo || bridgedLogo}
            alt="Logo"
          />
        </div>
        <h3 className="ragTopBarHeading">
          {engagementTitle || "Ask a question related to article."}
        </h3>

        <p
          style={{ fontWeight: 400, opacity: isLoading ? 1 : 0 }}
          className="primaryTextColor"
        >
          {isLoading ? ragLoaderTexts[loaderTextIndex] : "Not Loading"}
        </p>
      </div>

      {/* <TopBarFAQ
        setInputQuery={setInputQuery}
        handleGenerateAnswer={handleGenerateAnswer}
      /> */}

      <div style={{ height: "60px", marginTop: "var(--mpr-1-2)" }}>
        <div className="ragInputDiv">
          <Input
            placeholder="Enter Question"
            value={inputQuery}
            onChange={(e) => {
              setInputQuery(e.target.value);
              if (errors.inputQuery) {
                setErrors({ ...errors, inputQuery: undefined });
              }
            }}
            error={errors.inputQuery}
          />
          <Button isLoading={isLoading} onClick={handleGenerate}>
            {generateText}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
