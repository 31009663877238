import React from "react";
import { useSelector } from "react-redux";

const defaultQuestions = [
  "Who will be affected by Google's decision to keep third-party cookies?",
  "Does Google's Privacy Sandbox comply with privacy laws?",
  "How will the advertising industry be impacted by Google's decision?",
];

function BodyFAQ({ setInputQuery, handleGenerateAnswer }) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const suggestedQuestions = useSelector(
    (state) => state.language.data.suggestedQuestions
  );

  if (questions?.length) {
    return (
      <div style={{ paddingBottom: "var(--mpr-1-2)" }}>
        <div className="ragBodyFaqs">
          <h4>{suggestedQuestions}</h4>

          <ul>
            {questions?.map((data, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      setInputQuery(data);
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  return <></>;
}

export default BodyFAQ;
