import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../../utils/components/Icon";
import { useSelector } from "react-redux";
import Feedback from "../../reusable/components/feedbacks/Feedback";
import Markdown from "react-markdown";
import Divider from "../../../../utils/components/Divider";
import ScrollAnimation from "../../../../utils/components/scrollAnimation/ScrollAnimation";
import BodyFAQ from "./BodyFAQ";

function Body({
  responseData,
  isRagCollapsed,
  setInputQuery,
  handleGenerateAnswer,
}) {
  const questions = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData?.questions
  );

  const [isVisible, setIsVisible] = useState(false);
  const { answerText, questionText, referenceArticle } = useSelector(
    (state) => state.language.data
  );

  const lastElementRef = useRef(null);
  const scrollableBodyRef = useRef(null);

  // useEffect(() => {
  //   if (lastElementRef.current) {
  //     lastElementRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [responseData]);

  useEffect(() => {
    if (!isRagCollapsed) {
      setTimeout(() => {
        function handleScroll() {
          if (scrollableBodyRef.current) {
            const { scrollHeight, scrollTop, clientHeight } =
              scrollableBodyRef.current;
            const isScrollable = scrollHeight > clientHeight;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 15; // Adjusted for the 20px safe zone

            if (isScrollable) {
              if (isAtBottom) {
                setIsVisible(false);
              } else {
                setIsVisible(true);
              }
            } else {
              setIsVisible(false);
            }
          }
        }

        handleScroll();

        if (scrollableBodyRef.current) {
          scrollableBodyRef.current.addEventListener("scroll", handleScroll);
        }

        return () => {
          if (scrollableBodyRef.current) {
            scrollableBodyRef.current.removeEventListener(
              "scroll",
              handleScroll
            );
          }
        };
      }, 500);
    }
  }, [scrollableBodyRef, isRagCollapsed]);

  return (
    <>
      {!isRagCollapsed && (
        <>
          {responseData?.length === 0 && (
            <BodyFAQ
              setInputQuery={setInputQuery}
              handleGenerateAnswer={handleGenerateAnswer}
            />
          )}
        </>
      )}
      <div
        className="ragBody customScrollBar"
        style={{
          overflow: responseData?.length > 1 && "scroll",
        }}
        ref={scrollableBodyRef}
      >
        {responseData?.map((data, index) => {
          const responseAnswer = data?.response;
          const isLastElement = index === responseData?.length - 1;
          const ragResultId = data?.ragResultId;

          return (
            <div
              style={{
                marginBottom: responseData?.length === 1 && "var(--mpr-3)",
              }}
            >
              <div key={index} ref={isLastElement ? lastElementRef : null}>
                <div className="ragAnswerDiv">
                  <h4>{questionText}:</h4>
                  <h3>{data?.question}</h3>

                  <h4>{answerText}:</h4>
                  {(data?.type === "para_answer" || !data?.type) && (
                    <h3>{responseAnswer}</h3>
                  )}

                  {data?.type === "iec_answer" && (
                    <Markdown>{responseAnswer}</Markdown>
                  )}
                </div>
                {data?.reference?.length ? (
                  <div
                    className="ragReferenceDiv"
                    style={{
                      marginTop: "var(--mpr-2)",
                    }}
                  >
                    <h4>{referenceArticle}:</h4>
                    <ul className="ragReferenceLinks">
                      {data?.reference?.map((link, index) => {
                        return (
                          <li key={index}>
                            <a
                              href={link?.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link?.title}
                              <Icon
                                name="ri-external-link-line"
                                style={{ fontSize: "15px" }}
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}

                <div
                  style={{
                    marginTop: "var(--mpr-2)",
                  }}
                >
                  <Feedback ragResultId={ragResultId} />
                </div>

                {index !== responseData?.length - 1 && (
                  <Divider style={{ marginTop: "var(--mpr-3)" }} />
                )}
              </div>
            </div>
          );
        })}

        {!isRagCollapsed && (
          <ScrollAnimation isVisible={isVisible} style={{ left: "90%" }} />
        )}
      </div>
    </>
  );
}

export default Body;
